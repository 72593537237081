import React from 'react'

const Legal = ({ items }) => (
  <ul className="legal">
    {items ? items.map((item, i) => (
      <li key={i}><a href={item.value}>{item.label}</a></li>
    )) : null}
  </ul>
)

export default Legal
