import React from 'react'

const SocialMedia = ({ icons }) => (
  <ul className="social-media">
    {icons ? icons.map((item, i) => (
      <li key={i}>
        <a href={item.url}>
          <span className={`fab ${item.icon}`}></span>
        </a>
      </li>
    )) : null}
  </ul>
)

export default SocialMedia
