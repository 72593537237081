import React from 'react'
import Helmet from 'react-helmet'

import NavBar from './NavBar'
import Footer from './Footer'
import Cookie from './Cookie'
// import Favicon from './Favicon'

import apple1 from './Favicon/apple-touch-icon-57x57.png'
import apple2 from './Favicon/apple-touch-icon-114x114.png'
import apple3 from './Favicon/apple-touch-icon-72x72.png'
import apple4 from './Favicon/apple-touch-icon-144x144.png'
import apple5 from './Favicon/apple-touch-icon-60x60.png'
import apple6 from './Favicon/apple-touch-icon-120x120.png'
import apple7 from './Favicon/apple-touch-icon-76x76.png'
import apple8 from './Favicon/apple-touch-icon-152x152.png'

import web1 from './Favicon/favicon-196x196.png'
import web2 from './Favicon/favicon-96x96.png'
import web3 from './Favicon/favicon-32x32.png'
import web4 from './Favicon/favicon-16x16.png'
import web5 from './Favicon/favicon-128.png'

import mstile1 from './Favicon/mstile-144x144.png'
import mstile2 from './Favicon/mstile-70x70.png'
import mstile3 from './Favicon/mstile-150x150.png'
import mstile4 from './Favicon/mstile-310x150.png'
import mstile5 from './Favicon/mstile-310x310.png'

import './all.sass'

const TemplateWrapper = ({
  children,
  title,
  description,
  socialMedia,
  legal
}) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={apple1} />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href={apple2} />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href={apple3} />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href={apple4} />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href={apple5} />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href={apple6} />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href={apple7} />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href={apple8} />
      <link rel="icon" type="image/png" sizes="196x196" href={web1} />
      <link rel="icon" type="image/png" sizes="96x96" href={web2} />
      <link rel="icon" type="image/png" sizes="32x32" href={web3} />
      <link rel="icon" type="image/png" sizes="16x16" href={web4} />
      <link rel="icon" type="image/png" sizes="128x128" href={web5} />
      <meta name="application-name" content="&nbsp;"/>
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content={mstile1} />
      <meta name="msapplication-square70x70logo" content={mstile2} />
      <meta name="msapplication-square150x150logo" content={mstile3} />
      <meta name="msapplication-wide310x150logo" content={mstile4} />
      <meta name="msapplication-square310x310logo" content={mstile5} />
    </Helmet>
    <Cookie />
    {children}
    <Footer
      legal={legal}
      socialMedia={socialMedia}
    />
  </div>
)

export default TemplateWrapper
