import React from 'react'
import SocialMedia from '../SocialMedia'
import Legal from '../Legal'

const Footer = ({
  legal,
  socialMedia
}) => (
  <footer>
    <nav>
      <Legal items={legal} />
      <a href="//bbtv.com" className="bbtv">Powered by <img src="/img/bbtv-interactive.svg" alt="bbtv logo" /></a>
      <SocialMedia icons={socialMedia} />
    </nav>
  </footer>
)

export default Footer
