import React, {Component} from 'react';

const tagManager = 'UA-203068826-8'

const allowAnalytics = () => {
  if(tagManager !== '') {
    const scriptGtag = document.createElement('script');
    const script = document.createElement('script');
    scriptGtag.src = `https://www.googletagmanager.com/gtag/js?id=${tagManager}`;
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${tagManager}');
    `;

    (document.getElementsByTagName( "body" )[ 0 ]).appendChild( scriptGtag );
    (document.getElementsByTagName( "body" )[ 0 ]).appendChild( script );
  }
}

const allowCookies = () => {
  allowAnalytics();
  localStorage.setItem(`allowCookies-${tagManager}`, true);
}

const notAllowCookies = () => {
  localStorage.setItem(`allowCookies-${tagManager}`, false);
}

class Cookie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookie: false
    };
  }

  componentDidMount() {
    var cookiesAllowed = localStorage.getItem(`allowCookies-${tagManager}`);

    if(!cookiesAllowed) {
      this.setState({
        showCookie: true
      })
    }

    if(cookiesAllowed === 'true') {
      allowCookies();
      this.setState({
        showCookie: false
      })
    }
    if(cookiesAllowed === 'false') {
      notAllowCookies();
      this.setState({
        showCookie: false
      })
    }
  };

  render() {
    return (
      <div id="cookie-eu" className="cookie-eu" style={this.state.showCookie ? {display: 'flex'} : {display: 'none'}}>
        <main>
          <h3>This website uses cookies to ensure you the best experience on our website. <a href="//www.bbtv.com/privacy-policy" target="_blank" rel="noopener noreferrer">Learn more</a></h3>
        </main>
        <aside>
          <button
            className="decline"
            onClick={() => (
            localStorage.setItem(`allowCookies-${tagManager}`, false),
            this.setState({ showCookie: false })
          )}>Decline</button>
          <button
            className="accept"
            onClick={() => (
            allowAnalytics(),
            localStorage.setItem(`allowCookies-${tagManager}`, true),
            this.setState({ showCookie: false })
          )}>Accept</button>
        </aside>
      </div>
    )
  }
}

export default Cookie
