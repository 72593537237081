import React from 'react'

const isSticky = sticky => sticky ? 'sticky' : ''
const isShowingNav = nav => nav ? 'show' : ''

class NavBar extends React.Component {
  constructor() {
    super()
    this.state = {
      stickyMenu: false,
      showNav: false
    }
  }

  handleScroll = event => {
    const currentTop = document.documentElement.scrollTop;

    currentTop > 340 ? this.setState({stickyMenu: true}) : this.setState({stickyMenu: false});
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  render() {
    return (
      <nav className={`nav-bar ${isSticky(this.state.stickyMenu)} ${isShowingNav(this.state.showNav)}`}>
        <button
          onClick={() => this.setState({showNav: true})}
          className="open-menu">Menu <span className={`fa fa-bars`}></span>
        </button>
        <h1>Logo</h1>
        <aside>
          <button
            onClick={() => this.setState({showNav: false})}
            className="open">Close <span className={`fa fa-times`}></span>
          </button>
          <ul>
            <li onClick={() => (
              this.setState({showNav: false}),
              document.getElementById("about").scrollIntoView()
            )}>About</li>
            <li><a href="">Facebook</a></li>
            <li><a href="">Twitter</a></li>
            <li onClick={() => (
              this.setState({showNav: false}),
              window.scrollTo(0, document.body.scrollHeight)
            )}>Contact</li>
          </ul>
          <div className="background" onClick={() => this.setState({showNav: false})}></div>
        </aside>
      </nav>
    )
  }
}

export default NavBar
